/* Basic body styling */
.wrapper {
    font-family: Arial, sans-serif;
    background-color: #f0f0f5;
    padding: 30px;
    display: flex;
    justify-content: center;
  }
  .content{
    display: flex;
    justify-content: space-around;
    max-width:1400px;
  }
  .content>div{
    width:40%;
  }
  .description h3{
    color:var(--general-blue);
    font-size:4rem;
    font-weight:lighter;
    margin-bottom:20px;
    text-align:left;
  }
  .description p{
    font-size:1.1rem;
  }
  
  /* Center the estimate form */
  .estimateForm {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  .estimateForm form{
    display:flex;
    flex-direction:column;
    align-items: center;
  }
  .formSection{
    border-bottom:1px solid black;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-bottom:20px;
    margin-bottom:20px;
    height:auto;
  }
  .formSection img{
    width:150px;
    height:150px;
  }
  
  .labelAndSelect{
    width:85%;
  }
  .labelAndInput{
    width:85%;
    margin-bottom:10px;
  }
  .labelAndInput input{
    border:1px solid black;
    padding:10px;
    width:100%;
    border-radius:5px;
    box-sizing: border-box;
  }
  .labelAndCheck{
    width:100%;
    display:flex;
    align-items:center;
    gap:10px;
  }
  .labelAndCheck input{
    width:20px;
    height:20px;
  }
  .labelAndInput textarea{
    width:100%;
  }
  /* Form label styling */
  .estimateForm label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  .estimateForm select{
    width:100%;
    margin:0px;
    border-radius:10px;
    padding:10px;
    margin-bottom:20px;
  } 
  
  .checkboxes {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    width:85%;
  }
  .checkbox{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .checkbox img{
    width:50px;
    height:50px;
  }
  
  .checkboxes label {
    font-size: 14px;
    color: #333;
  }
  
  .cleaningDetails {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    transition: all 0.5s ease;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    gap: 15px;
    border:1px solid black;
    margin-bottom:40px;
  }
  
  
  .estimateForm h2 {
    color: var(--nav-background);
    margin-bottom: 20px;
    font-size: 25px;
  }

  .estimateForm h3 {
    color: var(--nav-background);
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  /* Table styling */
  table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  table td {
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
    font-size: 16px;
  }
  
  table td:last-child {
    text-align: right;
    font-weight: bold;
  }
  .total{
    font-weight:bold;
    background-color: #f9f9f9;
  }
  
  /* Button styling (if you add buttons later) */
  .center{
    text-align:center;
  }
  button:hover {
    background-color: var(--nav-background);
  }
  
  /* Animation for dropdown */
  .animateDropdown {
    animation: slide-down 0.5s ease forwards;
  }
  .address{
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
    transition: all 0.5s ease;
    display:flex;
    flex-direction:column;
    align-items: center;
    gap: 15px;
    border:1px solid black;
    margin-bottom:40px;
  }
  .infoIcon {
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  
  .infoIcon::after {
    content: attr(data-tooltip); /* Use the data-tooltip attribute for the content */
    position: absolute;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 6px 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
  }
  
  .infoIcon:hover::after {
    visibility: visible;
    opacity: 1;
  }
  

  .hiddenCheckbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  
  .customCheckbox {
    cursor: pointer;
    position: relative;
    padding-left: 25px; /* space for our custom arrow */
  }
  
  .customCheckbox::before {
    content: "▼"; /* default arrow */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* When the checkbox is checked, change the arrow */
  .hiddenCheckbox:checked + .customCheckbox::before {
    content: "▲";
  }

  .submitButton{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    margin-top:20px;
  }

  .estimateWrap{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes slide-down {
    0% {
      max-height: 0;
      opacity: 0;
    }
    100% {
      max-height: auto;
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .content{
        padding:0px;
        flex-direction:column;
    }
    .estimateForm{
        width:100%;
    }
    .description h3{
      font-size:calc(3* calc(.012* min(100vh, 900px)) + 1rem);
    }
    .content>div{
      width:auto;
      padding:20px;
    }
  }